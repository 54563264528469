import React from 'react';
import logo from '../images/logo.png'
import {Link} from 'react-router-dom'

function Header(){
	return(
		<>
			<header>
				<nav className="navbar navbar-expand-lg navbar-light bg-light header_section">
				  <div className="container">
				    <a className="navbar-brand" href="/">
				    	<img className="img-fluid" src={logo} />
				    </a>
				    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				      <span className="navbar-toggler-icon"></span>
				    </button>
				    <div className="collapse navbar-collapse" id="navbarSupportedContent">
				      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
				        <li className="nav-item">
				          <a className="nav-link active" href="#howitwork">How It Works</a>
				        </li>
				        <li className="nav-item">
				          <a className="nav-link" href="#features">Features</a>
				        </li>
				        <li className="nav-item">
				          <a className="nav-link" href="#testimonials">Testimonials</a>
				        </li>	        
				        <li className="nav-item">
				          <a className="nav-link" href="#costcalculator">Cost Calculator</a>
				        </li>
				      </ul>
				      <form className="d-flex">
				        <a className="btn btn_secondary me-10" href="https://app.verifapp.com/">Login</a>
				        <Link to="/getstarted" className="btn btn_primary">Get Started</Link>
				      </form>
				    </div>
				  </div>
				</nav>
			</header>
		</>
	)
}

export default Header;