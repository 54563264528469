import React,{useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import GetStartedBanner from '../images/get_started_banner_img.png'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField,TextAreaField} from '../components/form'
import * as Yup from 'yup';
import { fetchWrapper } from '../helpers';
import {useNavigate} from 'react-router-dom';

function GetStartedPage(){
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    contact_no:'',
    monthly_message_traffic:'',  
    
  });

  const FormSchema = Yup.object().shape({
	  full_name: Yup.string().required('Required'),
	  email: Yup.string().required('Required'),
	  contact_no: Yup.string().required('Required'),
	  monthly_message_traffic: Yup.string().required('Required'),
	  
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/get-touch/create`;
  		await fetchWrapper.post(url,data)
  		console.log(data)
  		navigate('/thankyou')	
	}

	const messagelist = {
	    data: [
	        { id: '200,000', name: '200,000' },
	        { id: '200,000', name: '200,000' },
	        { id: '200,000', name: '200,000' },
	    ]
	};


	return(
		<>
			<Header />
			<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>
			<div className="get_started_page">
				<div className="container">
					<div className="row">
						<div className="col-sm-4">
							<div className="text_section">
								<h1>Let’s Get Started</h1>

								<p>For the purpose of business, your details are required.</p>

								<div className="form_section">
									
									<InputText name="full_name" title="Your name*" />
									
									<InputText name="email" title="Email Address" />

									<InputText name="contact_no" title="Phone number" />

									<SelectField name="monthly_message_traffic" title="Potential Monthly Messaging Traffic" list={messagelist} />

									<button type="button" className="btn btn_submit" type="submit">Submit</button>
								</div>
							</div>
						</div>
						<div className="col-sm-2"></div>
						<div className="col-sm-6">
							<div className="img_section">
								<img className="img-fluid" src={GetStartedBanner} />
							</div>
						</div>
					</div>
				</div>
			</div>
			</Form>
			<Footer />
		</>
	)
}

export default GetStartedPage;