import React from 'react';
import Header from '../components/Header'

function PrivacyPolicy(){
	return(
		<>
			<Header />
			
			<div className="privacy_policy_page">
				<div className="container">
					<h1>Privacy Policy</h1>

					<p>Last updated: September 17, 2024</p>

					<p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>

					<p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.</p>

					<h3>Interpretation and Definitions</h3>

					<h3>Interpretation</h3>

					<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

					<h3>Definitions</h3>

					<p>For the purposes of this Privacy Policy:</p>

					<ul>
						<li>Account means a unique account created for You to access our Service or parts of our Service.</li>

						<li>Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
						<li>Application refers to VerifApp, the software program provided by the Company.</li>
						<li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to VerifApp, 86-90, Paul Street, London EC2A 4NE, United Kingdom..</li>
						<li>Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
						<li>Country refers to: United Kingdom</li>
						<li>Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
						<li>Personal Data is any information that relates to an identified or identifiable individual.</li>
						<li>Service refers to the Application or the Website or both.</li>
						<li>Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
						<li>Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
						<li>Website refers to VerifApp, accessible from <a href="#">verifapp.com</a></li>
						<li>You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
					</ul>

					<h3>Retention of Your Personal Data</h3>

					<p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>

					<p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

					<h3>Transfer of Your Personal Data</h3>

					<p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>

					<p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>

					<p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>

					<h3>Delete Your Personal Data</h3>

					<p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>

					<p>Our Service may give You the ability to delete certain information about You from within the Service.</p>

					<p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>

					<p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>

					<h3>Disclosure of Your Personal Data</h3>

					<h3>Business Transactions</h3>

					<p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

					<h3>Law enforcement</h3>

					<p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>

					<h3>Other legal requirements</h3>

					<p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>

					<ul>
						<li>Comply with a legal obligation</li>
						<li>Protect and defend the rights or property of the Company</li>
						<li>Prevent or investigate possible wrongdoing in connection with the Service</li>
						<li>Protect the personal safety of Users of the Service or the public</li>
						<li>Protect against legal liability</li>
					</ul>

					<h3>Security of Your Personal Data</h3>

					<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>

					<h3>Children's Privacy</h3>

					<p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>

					<p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>

					<h3>Links to Other Websites</h3>

					<p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>

					<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

					<h3>Changes to this Privacy Policy</h3>

					<p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>

					<p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>

					<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

					<h3>Contact Us</h3>

					<p>If you have any questions about this Privacy Policy, You can contact us:</p>

					<ul>
						<li>By email: support@verifapp.com</li>

						<li>By phone number: +447511029390</li>
					</ul>

					<p>Generated using TermsFeed Privacy Policy Generator</p>
				</div>
			</div>

			<footer className="footer_privacy_section">
				<div className="container">
					<p>Copyright © 2024 VerifApp</p>
				</div>
			</footer>
		</>
	)
}

export default PrivacyPolicy;