import React,{useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import check_thanks_icon from '../images/check_thanks_icon.png'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField,TextAreaField} from '../components/form'
import * as Yup from 'yup';
import { fetchWrapper } from '../helpers';

function ThankYouPage(){
	return(
		<>
			<Header />
			
			<div className="thankyou_page">
				<div className="container">
					
					<div className="text_section">
						<img src={check_thanks_icon} />

						<h1>Thank You !</h1>

						<p>We have received your request. Once of our Representative will get back to you.</p>
					</div>
					
				</div>
			</div>
			
			<Footer />
		</>
	)
}

export default ThankYouPage;