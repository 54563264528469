import React from 'react';
import Header from '../components/Header.js'
import Footer from '../components/Footer.js'
import home_banner_img from '../images/home_banner_img.png';
import how_banner_img from '../images/how_banner_img.png';
import integation_icon from '../images/integration_icon.png';
import capable_portal_icon from '../images/capable_portal_icon.png';
import ux_focused_icon from '../images/ux_focused_icon.png';
import documentation_icon from '../images/documentation_icon.png';
import support_team_icon from '../images/support_team_icon.png';
import security_check_icon from '../images/security_check_icon.png';
import otp_send_img from '../images/otp_send_img.png';
import testimonial_img from '../images/testimonial_img.png';
import check_circle from '../images/check_circle.png'


function Home(){
	return(
		<>
			<Header />

			<div className="banner_section">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-sm-4">
							<div className="text_section">
								<h1>Verify Customers In A Safe, Quick & Effiecient Way</h1>
								<p>Verifapp helps you verify customers in the most cost-efficient and optimized way.</p>

								<button type="button" className="btn btn_primary">Learn More</button>
							</div>
						</div>

						<div className="col-sm-8">
							<div className="text-end">
								<img className="img-fluid" src={home_banner_img} />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="how_it_work_section" id="howitwork">
				<div className="container">
					<div className="main_text_section text-center">
						<h1>How It Works</h1>
						<p>Following is the end to end process, that happens when your customer is verified</p>
					</div>

					<div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
					  <div className="carousel-inner">
					    <div className="carousel-item active">
					    	<div className="slider_inner_section">
					    		<div className="text_section">
							      	<h1>Customers Performs An Action That Requires Verification</h1>
							      	<p>Customers is asked for phone number if not present previously</p>
						      	</div>

						      	<div className="img_section">
							      	<img src={how_banner_img} /> 
						      	</div>
					    	</div>
					    </div>
					    <div className="carousel-item">
					      <div className="slider_inner_section">
					    		<div className="text_section">
							      	<h1>Customers Performs An Action That Requires Verification</h1>
							      	<p>Customers is asked for phone number if not present previously</p>
						      	</div>

						      	<div className="img_section">
							      	<img src={how_banner_img} />
						      	</div>
					    	</div>
					    </div>
					    <div className="carousel-item">
					      <div className="slider_inner_section">
					    		<div className="text_section">
							      	<h1>Customers Performs An Action That Requires Verification</h1>
							      	<p>Customers is asked for phone number if not present previously</p>
						      	</div>

						      	<div className="img_section">
							      	<img src={how_banner_img} />
						      	</div>
					    	</div>
					    </div>
					  </div>
					  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
					    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
					    <span className="visually-hidden">Previous</span>
					  </button>
					  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
					    <span className="carousel-control-next-icon" aria-hidden="true"></span>
					    <span className="visually-hidden">Next</span>
					  </button>
					</div>
				</div>
			</div>

			<div className="features_section" id="features">
				<div className="container">
					<h1>Features</h1>

					<div className="row">
						<div className="col-sm-4">
							 <div className="card_section">
							 	<div className="icon_section">
							 		<img className="img-fluid" src={integation_icon} />
							 	</div>

							 	<div className="text_section">
							 		<h2>Easy Integration</h2>
							 		<p>Verifapp SDK can be easily integrated</p>
							 	</div>
							 </div>
						</div>

						<div className="col-sm-4">
							 <div className="card_section">
							 	<div className="icon_section bg_green">
							 		<img className="img-fluid" src={capable_portal_icon} />
							 	</div>

							 	<div className="text_section">
							 		<h2>Highly Capable Portal</h2>
							 		<p>Execute, Analyze and Optimize throught the Portal</p>
							 	</div>
							 </div>
						</div>

						<div className="col-sm-4">
							 <div className="card_section">
							 	<div className="icon_section bg_yellow">
							 		<img className="img-fluid" src={ux_focused_icon} />
							 	</div>

							 	<div className="text_section">
							 		<h2>UX Focused</h2>
							 		<p>We make sure that your customer’s experience is unharmed.</p>
							 	</div>
							 </div>
						</div>
					</div>

					<div className="row">
						<div className="col-sm-4">
							 <div className="card_section">
							 	<div className="icon_section">
							 		<img className="img-fluid" src={documentation_icon} />
							 	</div>

							 	<div className="text_section">
							 		<h2>Documentation</h2>
							 		<p>Easy to understand guides for you to use and integrate verifapp platform</p>
							 	</div>
							 </div>
						</div>

						<div className="col-sm-4">
							 <div className="card_section">
							 	<div className="icon_section bg_green">
							 		<img className="img-fluid" src={support_team_icon} />
							 	</div>

							 	<div className="text_section">
							 		<h2>24/7 Support Team</h2>
							 		<p>In every step of your journey we are with you.</p>
							 	</div>
							 </div>
						</div>

						<div className="col-sm-4">
							 <div className="card_section">
							 	<div className="icon_section bg_yellow">
							 		<img className="img-fluid" src={security_check_icon} />
							 	</div>

							 	<div className="text_section">
							 		<h2>Highly Secured</h2>
							 		<p>All data is saved in highly secured servers.</p>
							 	</div>
							 </div>
						</div>
					</div>
				</div>
			</div>

			<div className="tryout_verification_section">
				<div className="container">
					<div className="row">
						<div className="col-sm-6">
							<div className="text_section">
								<h1>Tryout the strength of our platfrom by running a test verification.</h1>
								<p>Enter your Phone Number below. You can observe the bare-bone progress on the side.</p>
								<input type="text" placeholder="Enter your phone number" />
								<br/>
								<button type="button" className="btn btn_primary_small">Try Verification</button>
							</div>
						</div>

						<div className="col-sm-6">
							<div className="text-end">
								<img className="img-fluid" src={otp_send_img} />
							</div>	
						</div>
					</div>
				</div>
			</div>

			<div className="testimonial_section" id="testimonials">
				<div className="container">
					<div className="main_text_section text-center">
						 <h1>Testimonials</h1>
						 <p>What our cllients Say About Us</p>
					</div>

					<div id="carouselExampleControls1" className="carousel slide" data-bs-ride="carousel">
					  	<div className="carousel-inner">
						    <div className="carousel-item active">
						      	<div className="slider_inner_section">
						      		<img className="img-fluid" src={testimonial_img} />
						      		<div className="text_section">
						      			<h1>It is very easy to use and integrate. The customer acquisition rate has increase by 30%</h1>
						      			<p>Joseph Shonida - CEO</p>
						      		</div>
						      	</div>
						    </div>
						    <div className="carousel-item">
						      	<div className="slider_inner_section">
						      		<img className="img-fluid" src={testimonial_img} />
						      		<div className="text_section">
						      			<h1>It is very easy to use and integrate. The customer acquisition rate has increase by 30%</h1>
						      			<p>Joseph Shonida - CEO</p>
						      		</div>
						      	</div>
						    </div>
						    <div className="carousel-item">
						      	<div className="slider_inner_section">
						      		<img className="img-fluid" src={testimonial_img} />
						      		<div className="text_section">
						      			<h1>It is very easy to use and integrate. The customer acquisition rate has increase by 30%</h1>
						      			<p>Joseph Shonida - CEO</p>
						      		</div>
						      	</div>
						    </div>
					  	</div>
					  	<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="prev">
						    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
						    <span className="visually-hidden">Previous</span>
					  	</button>
					  	<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="next">
						    <span className="carousel-control-next-icon" aria-hidden="true"></span>
						    <span className="visually-hidden">Next</span>
					  	</button>
					</div>
				</div>
			</div>

			<div className="cost_calculator_section" id="costcalculator">
				<div className="container">
					<div className="row">
						<div className="col-sm-6">
						 	<div className="text_section">
						 		<h1>Cost Calculator</h1>
						 		<p>Our pricing structure is flexible to suit best for your needs, you can make estimations based on your preference.</p>
						 		<button type="button" className="btn btn_primary_small">See Pricing</button>
						 	</div>
						 </div>

						 <div className="col-sm-6">
						 	<div className="form_section">
						 		<div className="row">
						 			<div className="col-sm-6">
						 				<label>Verification Mode</label>
						 				<select className="form-select" aria-label="Default select example">
										  <option selected>Auto Detect</option>
										</select>
						 			</div>

						 			<div className="col-sm-6">
						 				<label>Region of Contact</label>
						 				<select className="form-select" aria-label="Default select example">
										  	<option value="USD">🇺🇸&emsp;US</option>
										    <option value="USD">🇺🇸&emsp;US</option>
										    <option value="USD">🇺🇸&emsp;US</option>
										</select>
						 			</div>
						 		</div>

						 		<button type="button" className="btn btn_primary_large">
						 			<p>Cost per Verification</p>

						 			<h1>$0.0025</h1>
						 		</button>
						 	</div>
						 </div>
					</div>
				</div>
			</div>

			<div className="start_using_section">
				<div className="container">
					<div className="row">
						<div className="col-sm-7">
							<div className="text_section">
								<h1>Start using the safest & fastest customer verification platform</h1>
								<button type="button" className="btn btn_primary">Get Started</button>
							</div>
						</div>

						<div className="col-sm-5">
							<div className="text_section">
								<p><img className="img-fluid" src={check_circle} /> 100% Delivery Rate</p>
								<p><img className="img-fluid" src={check_circle} /> 24/7 Effiecient Support</p>
								<p><img className="img-fluid" src={check_circle} /> Easy-to-use APIs</p>
								<p><img className="img-fluid" src={check_circle} /> Supportive Eco-system</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	)
}

export default Home;