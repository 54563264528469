import React from 'react';
import footer_logo from '../images/footer_logo.png';
import footer_mail_img from '../images/footer_mail_img.png';
import phone_icon from '../images/phone_icon.png';
import location_icon from '../images/location_icon.png';

function Footer(){
	return(
		<>
			<footer className="footer_section">
				<div className="container">
					<div className="row">
						<div className="col-sm-3">
							<img className="img-fluid" src={footer_logo} />
						</div>

						<div className="col-sm-2">
							<h2>Platform</h2>

							<ul>
								<li>
									<a href="/privacypolicy">Privacy Policy</a>
								</li>

								<li>
									<a href="/termsandservices">Terms of Services</a>
								</li>

								<li>
									<a href="/contactus">Contact Us</a>
								</li>
							</ul>
						</div>

						<div className="col-sm-3">
							<h2>Stay in touch</h2>

							<p><a href="mailto:support@verifapp.com"><img className="img-fluid" src={footer_mail_img} /> support@verifapp.com</a></p>

							<p><img className="img-fluid" src={phone_icon} /> +447511029390</p>

							<p><img className="img-fluid" src={location_icon} /> 86-90, Paul Street, London EC2A 4NE, United Kingdom.</p>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer;