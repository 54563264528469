import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndServices from './pages/TermsAndServices';
import GetStartedPage from './pages/GetStartedPage';
import ContactUsPage from './pages/ContactUsPage';
import ThankYouPage from './pages/ThankYouPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>            
        
        <Route path="/" element={<Home />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandservices" element={<TermsAndServices />} />
        <Route path="/getstarted" element={<GetStartedPage />} />
        <Route path="/contactus" element={<ContactUsPage />} />
        <Route path="/thankyou" element={<ThankYouPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
