import React,{useEffect,useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactUsBanner from '../images/contact_us_banner_img.png'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField,TextAreaField} from '../components/form'
import * as Yup from 'yup';
import { fetchWrapper } from '../helpers';
import {useNavigate} from 'react-router-dom';

function ContactUsPage(){
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone:'',
    company:'',  
    message:'',
    
  });

  const FormSchema = Yup.object().shape({
	  name: Yup.string().required('Required'),
	  email: Yup.string().required('Required'),
	  phone: Yup.string().required('Required'),
	  company: Yup.string().required('Required'),
	  message: Yup.string().required('Required'),
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/contact/create`;
  		await fetchWrapper.post(url,data)
  		navigate('/thankyou')	
	}

	return(
		<>
			<Header />
			<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>
			<div className="contact_us_page">
				<div className="container">
					<div className="text_section">
						<h1>Get in touch today</h1>
						<p>Lorem ipsum dolor sit amet consectetur adipiscing elit nulla adipiscing tincidunt interdum tellus du.</p>
					</div>

					<div className="row">	
						<div className="col-sm-5">
							<div className="img_section">
								<img className="img-fluid" src={ContactUsBanner} />
							</div>
						</div>

						<div className="col-sm-7">
							<div className="form_section">
								<div className="row">
									<div className="col-sm-6">
										<InputText name="name" title="Name" />
									</div>

									<div className="col-sm-6">
										<div className="field_section">
											<InputText name="email" title="Email" />
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-6">
										<div className="field_section">
											<InputText name="phone" title="Phone" />
										</div>
									</div>

									<div className="col-sm-6">
										<div className="field_section">
											<InputText name="company" title="Company" />
										</div>
									</div>
								</div>

								<div className="field_section">
									<TextAreaField name="message" title="Leave us message" />
								</div>

								<button className="btn btn_primary" type='submit'>Submit</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			</Form>

			<Footer />
		</>
	)
}

export default ContactUsPage;